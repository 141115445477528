<template>
  <div>
    <div class="box">
        <img src="../../assets/images/1stanniv/1stanniv.jpg" alt="">
         <div class="erweima-box">
            <img class="btn1" src="../../assets/images/1stanniv/btm1.png" alt="">
            <a href="http://onelink.to/xyx76x">
              <img class="btn2" src="../../assets/images/1stanniv/btm2.png" alt="">
            </a>
            <a href="http://onelink.to/xyx76x">
              <img style="width:110%;" class="btn2" src="../../assets/images/1stanniv/btm3.png" alt="">
            </a>
        </div>
        <div class="snsIconBox">
          <a href="https://www.facebook.com/travelcontents/">
              <img class="btn2" src="../../assets/images/1stanniv/snsIcon1.png" alt="">
            </a>
            <a href="https://www.instagram.com/travelcontents.tw/">
              <img class="btn2" src="../../assets/images/1stanniv/snsIcon2.png" alt="">
            </a>
            <a href="https://m.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured">
              <img class="btn2" src="../../assets/images/1stanniv/snsIcon3.png" alt="">
            </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
    img{
        width:100%;
    }

    .erweima-box {
        width: 25%;
        // height: auto;
        // width: 414px;
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn1 {
            width: 18%;
            height: 18%;
        }
        a {
            width: 38%;
            // height: 10%;
        }
    }
    .snsIconBox {
      width: 13%;
      position: absolute;
      bottom: 8%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      a{
        width: 24%;
        height: 24%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
}
</style>